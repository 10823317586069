import Vue from 'vue';
import Router from 'vue-router';
import Dashboard from './components/Dashboard'
import Sales from './components/Sales'
import Service from './components/Service'
import Tracking from './components/Tracking'
import TrackingDetail from './components/TrackingDetail'
import Vertrieb from './components/Vertrieb'
import VertriebAnalysis from './components/VertriebAnalysis'
import Login from './components/Login'
import Logout from './components/Logout'
import Activate from './components/Activate'
import Main from './components/Main'
import SettingsProfile from './components/SettingsProfile'
import SettingsUsers from './components/SettingsUsers'
import SettingsUsersInvite from './components/SettingsUsersInvite'
import ServiceDepartmentAnalysis from './components/ServiceDepartmentAnalysis'
import ServiceDepartmentComparison from './components/ServiceDepartmentComparison'
import ServiceCompetition from './components/ServiceCompetition'
import MechanicCompetition from './components/MechanicCompetition'
import ServiceDBAnalysis from './components/ServiceDBAnalysis'
import ServiceSalesPotential from './components/ServiceSalesPotential'
import CXBox from './components/CXBox'
import GroupsComparison from './components/GroupsComparison'
import Support from './components/Support'
import jwt_decode from 'jwt-decode';
import MarketChances from './components/MarketChances.vue';
Vue.use(Router)

const routes = [
    {
        path: '/login',
        component: Login,
        name: 'login',
        meta: {
            title: 'Login',
            auth: false
        }
    },
    {
        path: '/logout',
        component: Logout,
        name: 'logout',
        meta: {
            title: 'Logout',
            auth: true
        }
    },
    {
        path: '/support',
        component: Support,
        name: 'support',
        meta: {
            title: 'Support',
            auth: true
        }
    },
    {
        path: '/activate/:token',
        component: Activate,
        name: 'activate',
        meta: {
            title: 'Activate',
            auth: false
        }
    },
    {
        path: '*',
        component: Main,
        redirect: {
            name: 'service-departmentanalysis-overview'
        },
        children: [
            {
                path: '/dashboard',
                component: Dashboard,
                name: 'dashboard',
                redirect: {
                    name: 'service-departmentanalysis-overview'
                },
                meta: {
                    title: 'Start',
                    auth: true
                }
            },
            {
                path: '/service',
                redirect: {
                    name: 'service-departmentanalysis-overview'
                },
                component: Service,
                name: 'service',
                meta: {
                    title: 'Service',
                    auth: true
                },
                children: [
                    {
                        path: '/service/betriebsanalyse/uebersicht',
                        component: ServiceDepartmentAnalysis,
                        name: 'service-departmentanalysis-overview',
                        meta: {
                            title: 'Service',
                            auth: true,
                            type: 'departmentanalysis-overview',
                            subtitle: 'Betriebsanalyse'
                        },
                    },
                    {
                        path: '/service/betriebsvergleich/uebersicht',
                        component: ServiceDepartmentComparison,
                        name: 'service-departmentcomparison-overview',
                        meta: {
                            title: 'Service',
                            auth: true,
                            type: 'departmentcomparison-overview',
                            subtitle: 'Betriebsvergleich'
                        }
                    },
                    {
                        path: '/service/wettbewerb/uebersicht',
                        component: ServiceCompetition,
                        name: 'service-competition-overview',
                        meta: {
                            title: 'Service',
                            auth: true,
                            type: 'competition-overview',
                            subtitle: 'SB-Wettbewerb'
                        }
                    },
                    {
                        path: '/service/mechaniker-wettbewerb/uebersicht',
                        component: MechanicCompetition,
                        name: 'mechanic-competition-overview',
                        meta: {
                            title: 'Service',
                            auth: true,
                            type: 'mechanic-competition-overview',
                            subtitle: 'Mechaniker-Wettbewerb'
                        }
                    },
                    {
                        path: '/service/db-analyse/uebersicht',
                        component: ServiceDBAnalysis,
                        name: 'service-db-analysis-overview',
                        meta: {
                            title: 'Service',
                            auth: true,
                            type: 'db-analysis-overview',
                            subtitle: 'DB-Analyse'
                        }
                    },
                    {
                        path: '/service/vertriebs-potential/uebersicht',
                        component: ServiceSalesPotential,
                        name: 'service-sales-potential-overview',
                        meta: {
                            title: 'Service',
                            auth: true,
                            type: 'sales-potential-overview',
                            subtitle: 'Vertriebs-Potential'
                        }
                    },
                    {
                        path: '/service/cxbox/uebersicht',
                        component: CXBox,
                        name: 'cxbox-overview',
                        meta: {
                            title: 'Service',
                            auth: true,
                            type: 'cxbox-overview',
                            subtitle: 'CX-Box'
                        }
                    },
                    {
                        path: '/service/gruppenvergleich/uebersicht',
                        component: GroupsComparison,
                        name: 'gruppenvergleich-overview',
                        meta: {
                            title: 'Service',
                            auth: true,
                            type: 'gruppenvergleich-overview',
                            subtitle: 'Gruppenvergleich'
                        }
                    },
                    {
                        path: '/service/marktchancen/uebersicht',
                        component: MarketChances,
                        name: 'marktchancen-overview',
                        meta: {
                            title: 'Service',
                            auth: true,
                            type: 'marktchancen-overview',
                            subtitle: 'Marktchancen'
                        }
                    }
                ]
            },
            {
                path: '/sales',
                component: Sales,
                name: 'sales',
                meta: { 
                    title: 'Sales',
                    auth: true
                }
            },
            {
                path: '/tracking/detail/:id',
                component: TrackingDetail,
                name: 'tracking-detail',
                meta: {
                    title: 'Tracking',
                    auth: true
                }
            },
            {
                path: '/tracking',
                redirect: '/tracking/uebersicht/gesamt/1',
                name: 'tracking',
                meta: {
                    title: 'Tracking',
                    auth: true
                }
            },
            {
                path: '/tracking/uebersicht/:type/:page',
                component: Tracking,
                name: 'tracking-overview',
                meta: {
                    title: 'Tracking',
                    auth: true
                }
            },
            {
                path: '/vertrieb',
                redirect: {
                    name: 'sales-overview'
                },
                component: Vertrieb,
                name: 'vertrieb',
                meta: {
                    title: 'Vertrieb',
                    auth: true
                },
                children: [
                    {
                        path: '/vertrieb/uebersicht',
                        component: VertriebAnalysis,
                        name: 'sales-overview',
                        meta: {
                            title: 'Vertrieb',
                            auth: true,
                            type: 'sales-overview',
                            subtitle: 'Vertrieb'
                        },
                    }
                ]
            },
            {
                path: '/settings/profile',
                component: SettingsProfile,
                name: 'settings-profile',
                meta: {
                    title: 'Settings',
                    auth: true
                }
            },
            {
                path: '/settings/users',
                component: SettingsUsers,
                name: 'settings-users',
                meta: {
                    title: 'Settings',
                    auth: true
                }
            },
            {
                path: '/settings/users/invite',
                component: SettingsUsersInvite,
                name: 'settings-users-invite',
                meta: {
                    title: 'Settings',
                    auth: true
                }
            }
        ]
    }
]

let router = new Router({
    mode: 'history',
    routes
})

router.beforeEach((to, from, next) => {
    if(to.matched.some(record => record.meta.auth)) {
        const jwt = localStorage.getItem('jwt');
        if (jwt == null) {
            next({
                path: '/login',
                params: { nextUrl: to.fullPath }
            })
        } else {
            // Decode the JWT token to get its expiration date
            const decodedToken = jwt_decode(jwt);
            const currentTime = Date.now() / 1000; // Convert to seconds

            // Check if the token is expired
            if (decodedToken.exp < currentTime) {
                // Token is expired, redirect to login page
                next({
                    path: '/login',
                    params: { nextUrl: to.fullPath }
                });
            } else {
                let user = JSON.parse(localStorage.getItem('user'))
                if(to.matched.some(record => record.meta.admin)) {
                    if(user.admin == 1){
                        next()
                    }
                    else{
                        next({ name: 'userboard'})
                    }
                }else {
                    next()
                }
            }
        }
    } else if(to.matched.some(record => record.meta.guest)) {
        if(localStorage.getItem('jwt') == null){
            next()
        }
        else{
            next({ name: 'userboard'})
        }
    }else {
        next()
    }
})

export default router