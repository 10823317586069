<template>
    <div class="main" :style="{ width: mainWidth + 'px' }">
        <div class="main-content" v-show="show" v-if="$route.meta.title == 'Tracking'" key="tracking-view">
            <div class="title">{{ capitalizedTitle }}</div>
            <div class="subtitle">Ansichten</div>
            <div class="border"></div>
            <div class="nav-top-level"><a href="#">Ereignisse</a></div>
            <router-link class="nav-sub-level" :class="{ 'router-link-active': isActive('gesamt') }" tag="div"
                :to="{ name: 'tracking-overview', params: { type: 'gesamt', page: 1 }}">
                <a><i class="fas fa-circle"></i> Gesamt <u v-if="summary">{{ summary.gesamt }}</u></a>
            </router-link>
            <router-link class="nav-sub-level" :class="{ 'router-link-active': isActive('neu') }" tag="div"
                :to="{ name: 'tracking-overview', params: { type: 'neu', page: 1 }}">
                <a><i class="far fa-circle"></i> Neu <u v-if="summary">{{ summary.neu }}</u></a>
            </router-link>
            <router-link class="nav-sub-level" :class="{ 'router-link-active': isActive('in-bearbeitung') }" tag="div"
                :to="{ name: 'tracking-overview', params: { type: 'in-bearbeitung', page: 1 }}">
                <a><i class="far fa-dot-circle"></i> In Bearbeitung <u v-if="summary">{{ summary.in_bearbeitung
                }}</u></a>
            </router-link>
            <router-link class="nav-sub-level" :class="{ 'router-link-active': isActive('ueberfaellig') }" tag="div"
                :to="{ name: 'tracking-overview', params: { type: 'ueberfaellig', page: 1 }}">
                <a><i class="fas fa-exclamation-circle"></i> Überfällig <u v-if="summary">{{ summary.ueberfaellig
                }}</u></a>
            </router-link>
            <router-link class="nav-sub-level" :class="{ 'router-link-active': isActive('geschlossen') }" tag="div"
                :to="{ name: 'tracking-overview', params: { type: 'geschlossen', page: 1 }}">
                <a><i class="fas fa-check-circle"></i> Geschlossen <u v-if="summary">{{ summary.geschlossen }}</u></a>
            </router-link>
            <div class="border"></div>
            <div class="logo"></div>
        </div>
        <div class="main-content" v-show="show" v-else-if="$route.meta.title == 'Settings'" key="settings-view">
            <div class="title">{{ capitalizedTitle }}</div>
            <div class="subtitle">Ansichten</div>
            <div class="border"></div>
            <div class="nav-top-level"><a href="#">Einstellungen</a></div>
            <router-link class="nav-sub-level" :active="isActive('profil')" tag="div"
                :to="{ name: 'settings-profile' }">
                <a><i class="fas fa-cog"></i> Profil</a>
            </router-link>
            <div v-if="user">
                <router-link v-if="user.admin" class="nav-sub-level" :active="isActive('nutzer')" tag="div"
                    :to="{ name: 'settings-users' }">
                    <a><i class="fas fa-user-friends"></i>Nutzerverwaltung</a>
                </router-link>
            </div>
            <div class="border"></div>
            <div class="logo"></div>
        </div>
        <div class="main-content" v-show="show" v-else-if="$route.meta.title == 'Service'" key="service-view">
            <div class="title">{{ capitalizedTitle }}</div>
            <div class="subtitle">Ansichten</div>
            <div class="border"></div>

            <router-link class="nav-sub-level nav-sub-level-white accent"
                :class="{ 'router-link-active': isActive('departmentanalysis-overview') }" tag="div"
                :to="{ name: 'service-departmentanalysis-overview', params: { type: 'departmentanalysis-overview' }}">
                Betriebsanalyse
            </router-link>

            <div class="border"></div>

            <router-link class="nav-sub-level nav-sub-level-white accent"
                :class="{ 'router-link-active': isActive('departmentcomparison-overview') }" tag="div"
                :to="{ name: 'service-departmentcomparison-overview', params: { type: 'departmentcomparison-overview' }}">
                Betriebsvergleich
            </router-link>
            <div class="border"></div>
            <div v-if="user">
                <div v-if="user.views.gruppenvergleich">
                    <router-link class="nav-sub-level nav-sub-level-white accent"
                        :class="{ 'router-link-active': isActive('gruppenvergleich-overview') }" tag="div"
                        :to="{ name: 'gruppenvergleich-overview', params: { type: 'gruppenvergleich-overview' }}">
                        Gruppenvergleich
                    </router-link>
                    <div class="border"></div>
                </div>
            </div>
            <div v-if="user">
                <div v-if="user.views.mechanic_competition || user.views.competition">
                    <div class="nav-top-level">
                        <p>Wettbewerbe</p>
                    </div>
                    <div v-if="user.views.competition">
                        <router-link class="nav-sub-level nav-sub-level-white"
                            :class="{ 'router-link-active': isActive('competition-overview') }" tag="div"
                            :to="{ name: 'service-competition-overview', params: { type: 'competition-overview' }}">
                            Serviceberater
                        </router-link>

                    </div>
                    <div v-if="user.views.mechanic_competition">
                        <router-link class="nav-sub-level nav-sub-level-white"
                            :class="{ 'router-link-active': isActive('mechanic-competition-overview') }" tag="div"
                            :to="{ name: 'mechanic-competition-overview', params: { type: 'mechanic-competition-overview' }}">
                            Mechaniker
                        </router-link>

                    </div>
                    <div class="border"></div>
                </div>
            </div>
            <div v-if="user">
                <div v-if="user.views.db_analysis">
                    <router-link class="nav-sub-level nav-sub-level-white accent"
                        :class="{ 'router-link-active': isActive('db-analysis-overview') }" tag="div"
                        :to="{ name: 'service-db-analysis-overview', params: { type: 'db-analysis-overview' }}">
                        DB-Analyse
                    </router-link>
                    <div class="border"></div>
                </div>
            </div>
            <div v-if="user">
                <div v-if="user.views.sales_potential">
                    <router-link class="nav-sub-level nav-sub-level-white accent"
                        :class="{ 'router-link-active': isActive('sales-potential-overview') }" tag="div"
                        :to="{ name: 'service-sales-potential-overview', params: { type: 'sales-potential-overview' }}">
                        Vertriebs-Potential
                    </router-link>
                    <div class="border"></div>
                </div>
            </div>
            <div v-if="user">
                <div v-if="user.views.cxbox">
                    <router-link class="nav-sub-level nav-sub-level-white accent"
                        :class="{ 'router-link-active': isActive('cxbox-overview') }" tag="div"
                        :to="{ name: 'cxbox-overview', params: { type: 'cxbox-overview' }}">
                        CX-Box
                    </router-link>
                    <div class="border"></div>
                </div>
            </div>
            <div v-if="user">
                <div v-if="user.views.marktchancen">
                    <router-link class="nav-sub-level nav-sub-level-white accent"
                        :class="{ 'router-link-active': isActive('marktchancen-overview') }" tag="div"
                        :to="{ name: 'marktchancen-overview', params: { type: 'marktchancen-overview' }}">
                        Marktchancen
                    </router-link>
                    <div class="border"></div>
                </div>
            </div>
            <div class="logo"></div>
        </div>
        <div class="main-content" v-show="show" v-else-if="$route.meta.title == 'Vertrieb'" key="sales-view">
            <div class="title">{{ capitalizedTitle }}</div>
            <div class="subtitle">Ansichten</div>
            <div class="border"></div>
            <div class="nav-top-level"><a href="#">Vertriebsanalyse</a></div>
            <router-link class="nav-sub-level nav-sub-level-white"
                :class="{ 'router-link-active': isActive('sales-overview') }" tag="div"
                :to="{ name: 'sales-overview', params: { type: 'sales-overview' }}">
                Übersicht
            </router-link>
            <div class="logo"></div>
        </div>
        <div class="main-content" v-show="show" v-else key="default-view">
            <div class="title">{{ capitalizedTitle }}</div>
            <div class="subtitle">Ansichten</div>
            <div class="border"></div>
            <div class="nav-top-level"><a href="#">Schnelleinstieg</a></div>
            <div class="nav-sub-level router-link-active"><a href="#">Übersicht</a></div>
            <div class="nav-sub-level"><a href="#">Verkäufe</a></div>
            <div class="border"></div>
            <div class="nav-top-level"><a href="#">Letzte Woche</a></div>
            <div class="nav-sub-level"><a href="#">Übersicht</a></div>
            <div class="border"></div>
            <div class="logo"></div>
        </div>
        <div class="hide" v-if="show" @click="hideSidebar()"><i class="fas fa-angle-left"></i></div>
        <div class="show" v-else @click="showSidebar()"><i class="fas fa-angle-right"></i></div>
    </div>
</template>

<script>
export default {
    name: 'MainNavigation',
    data() {
        return {
            summary: false
        }
    },
    props: [
        'show',
        'user'
    ],
    methods: {
        hideSidebar() {
            this.$emit('hide', true)
        },
        showSidebar() {
            this.$emit('show', true)
        },
        loadSummary() {
            if (this.$route.meta.title == 'Tracking') {
                this.$http.get('/api/v1/tracking/summary',
                    {
                        headers: {
                            Authorization: 'Bearer ' + localStorage.getItem('jwt')
                        }
                    }
                ).then((response) => {
                    this.summary = response.data
                })
            }
        },
        isActive(type) {
            if (this.$route.params.type == type) {
                return true
            }
            return false
        },
    },
    computed: {
        capitalizedTitle() {
            const title = this.$route.meta.title
            return title.charAt(0).toUpperCase() + title.slice(1);
        },
        mainWidth() {
            if (this.show) {
                return 276
            }
            return 0
        }
    },
    mounted() {
        this.loadSummary()
    },
    watch: {
        '$route'() {
            this.loadSummary()
        }
    }
}
</script>

<style scoped>
.main {
    height: 100vh;
    background-color: #1B2142;
    float: left;
    box-sizing: border-box;
    transition: all 0.5s;
    position: fixed;
}

.main-content {
    transition: all 0.5s;
}

.title {
    font-size: 30px;
    color: white;
    font-weight: 700;
    margin-top: 122px;
    padding-left: 40px;
}

.subtitle {
    font-size: 20px;
    color: #FF8A00;
    padding-top: 55px;
    padding-left: 40px;
}

.border {
    height: 1px;
    width: auto;
    background-color: #FFFFFF;
    opacity: 0.08;
    margin-top: 6px;
    margin-bottom: 6px;
}

.nav-top-level {
    font-size: 16px;
    font-weight: 700;
    margin-top: 10px;
    margin-bottom: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 40px;
}

.nav-top-level a {
    text-decoration: none;
    color: #FFFFFF;
}

.nav-top-level p {
    text-decoration: none;
    color: #FFFFFF;
    margin-top: 5px;
    margin-bottom: 5px;
}



.nav-sub-level {
    font-size: 14px;
    font-weight: 400;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 25px;
    margin-left: 15px;
    margin-right: 15px;
    border-radius: 4px;
}

.nav-sub-level.accent {
    font-size: 16px;
    font-weight: 700;
}

.nav-sub-level u {
    color: rgba(157, 192, 233, 0.5);
    margin-left: 5px;
    text-decoration: none;
}

.nav-sub-level a,
.nav-sub-level-white {
    text-decoration: none;
    color: #FFFFFF;
}

.nav-sub-level-white:hover {
    cursor: pointer;
    background-color: rgba(157, 192, 233, 0.05);
}

.nav-sub-level i {
    font-size: 13px;
    margin-right: 8px;
    color: rgb(157, 192, 233);
}

.nav-sub-level.router-link-active {
    background-color: rgba(157, 192, 233, 0.2);
}

.nav-sub-level.router-link-active i {
    color: #FF8A00
}

.hide {
    height: 40px;
    width: 40px;
    line-height: 40px;
    position: absolute;
    bottom: 20px;
    margin-left: 226px;
    text-align: center;
    color: white;
    opacity: 0.5;
    cursor: pointer;
}

.hide:hover {
    opacity: 1;
}

.show {
    height: 40px;
    width: 40px;
    line-height: 40px;
    position: absolute;
    bottom: 20px;
    left: 10px;
    text-align: center;
    color: #1B2142;
    opacity: 0.5;
    cursor: pointer;
}

.show:hover {
    opacity: 1;
}

.logo {
    position: absolute;
    box-sizing: border-box;
    width: 276px;
    height: 40px;
    line-height: 40px;
    bottom: 60px;
    padding-left: 40px;
    font-weight: 700;
    font-size: 20px;
    color: #FFFFFF;
    background-image: url('/static/logo.svg');
    background-repeat: no-repeat;
    background-position: 42px center;
}

.logo i {
    color: #FF8A00;
    font-style: normal;
}

.user-fade-enter-active,
.user-fade-leave-active {
    transition: opacity .5s;
}

.user-fade-enter,
.user-fade-leave-to

/* .fade-leave-active below version 2.1.8 */
    {
    opacity: 0;
}
</style>